import React from 'react';
import { Spin } from 'antd';

export default class Loading extends React.Component {
	render(){
		return (
			<div style={{ height: '100%', width: '100%', position: 'absolute', zIndex: 10,top:'50%',textAlign:'center' }}>
				<Spin size="large"/>
			</div>
		)
	}
}
