import React from 'react';
import { HashRouter, Switch } from 'react-router-dom';
import { mapRoutes } from "./routes";

const App = () => {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          {
            mapRoutes()
          }
        </Switch>
      </HashRouter>
    </div>
  );
};

export default App;
