import React from 'react';
import Loadable from 'react-loadable';
import Loading from '../tools/Loading';
import { Route } from 'react-router-dom';

const RouteA = Loadable({
	loader: () => import('../containers/RouteA'),
	loading: Loading
});
const RouteB = Loadable({
	loader: () => import('../containers/RouteB'),
	loading: Loading
});
const RouteC = Loadable({
	loader: () => import('../containers/RouteC'),
	loading: Loading
});
const RedPacketTest = Loadable({ // 测试集合页
	loader: () => import('../containers/RedPacketTest'),
	loading: Loading
});
const RedPacketSpell = Loadable({ // 拼手气红包
	loader: () => import('../containers/RedPacketSpell'),
	loading: Loading
});
const RedPacketEarn = Loadable({ // 赚红包频道
	loader: () => import('../containers/RedPacketEarn'),
	loading: Loading
});
const NewComer = Loadable({ // 新人专享
	loader: () => import('../containers/NewComer'),
	loading: Loading
});
const OrderReturn = Loadable({ // 双11下单返现活动页
	loader: () => import('../containers/OrderReturn'),
	loading: Loading
});
const fiveball1 = Loadable({ // 5个球广告位
	loader: () => import('../containers/active/fiveball1'),
	loading: Loading
});
const LandingMiniFree = Loadable({ //9.9包邮-小程序外推页 === 9.9捡漏
	loader: () => import('../containers/active/LandingMiniFiveball1'),
	loading: Loading
});
const fiveball2 = Loadable({ // 5个球广告位
	loader: () => import('../containers/active/fiveball2'),
	loading: Loading
});
const fiveball3 = Loadable({ // 5个球广告位
	loader: () => import('../containers/active/fiveball3'),
	loading: Loading
});
const fiveball4 = Loadable({ // 5个球广告位
	loader: () => import('../containers/active/fiveball4'),
	loading: Loading
});
const fiveball5 = Loadable({ // 5个球广告位
	loader: () => import('../containers/active/fiveball5'),
	loading: Loading
});
const tonglan23 = Loadable({ // 5个球广告位
	loader: () => import('../containers/active/tonglan23'),
	loading: Loading
});
const shuangdan = Loadable({ // 5个球广告位
	loader: () => import('../containers/active/shuangdan'),
	loading: Loading
});
const fiveball8 = Loadable({ // 推广返佣tab列表
	loader: () => import('../containers/active/fiveball8'),
	loading: Loading
});
const fiveball9 = Loadable({ // 推广返佣tab列表分享后的落地页
	loader: () => import('../containers/active/fiveball9'),
	loading: Loading
});
const VipWelfare = Loadable({ // 双12会员福利
	loader: () => import('../containers/VipWelfare'),
	loading: Loading
});
const OrderReturnRule = Loadable({ // 双11下单返现活动规则页
	loader: () => import('../containers/OrderReturnRule'),
	loading: Loading
});
const Tactics = Loadable({ // 新人攻略
	loader: () => import('../containers/Tactics'),
	loading: Loading
});
const HelpCenter = Loadable({ // 帮助中心
	loader: () => import('../containers/HelpCenter'),
	loading: Loading
});
const Explain = Loadable({ // 红包说明
	loader: () => import('../containers/Explain'),
	loading: Loading
});
const ExplainSharing = Loadable({ // 共享经济合作伙伴协议
	loader: () => import('../containers/ExplainSharing'),
	loading: Loading
});
const VipExplain = Loadable({ // 会员说明
	loader: () => import('../containers/vip/explain'),
	loading: Loading
});
const VipInvite = Loadable({ // 会员制邀新
	loader: () => import('../containers/vip/invite'),
	loading: Loading
});
const VipInviteOut = Loadable({ // 会员制邀新
	loader: () => import('../containers/vip/invite/outIndex'),
	loading: Loading
});
const VipCenter = Loadable({ // 会员中心落地页面
	loader: () => import('../containers/vip/center'),
	loading: Loading
});
const OutVipCenter = Loadable({ // 会员中心落地页面
	loader: () => import('../containers/vip/center/outvipcenter'),
	loading: Loading
});
const VipRecord = Loadable({ // 会员制邀请记录
	loader: () => import('../containers/vip/record'),
	loading: Loading
});
const VipDetail = Loadable({ // 外推三期-会员详情页
	loader: () => import('../containers/vip/detail'),
	loading: Loading
});
const VipOrder = Loadable({ // 外推三期-确认订单页
	loader: () => import('../containers/vip/order'),
	loading: Loading
});
const VipSuccess = Loadable({ // 外推三期-支付成功页-前置
	loader: () => import('../containers/vip/success'),
	loading: Loading
});
const VipSuccessAfter = Loadable({ // 外推三期-支付成功页-后置
	loader: () => import('../containers/vip/successAfter'),
	loading: Loading
});
const UserAgreement = Loadable({ // 用户协议
	loader: () => import('../containers/UserAgreement'),
	loading: Loading
});
const PrivacyAgreement = Loadable({ // 隐私政策
	loader: () => import('../containers/PrivacyAgreement'),
	loading: Loading
});
const Landing = Loadable({ // 推广着陆页
	loader: () => import('../containers/Landing'),
	loading: Loading
});
const LandingKnightCard = Loadable({ // 骑士卡-外推页
    loader: () => import('../containers/LandingKnightCard'),
    loading: Loading
});
const KnightCard = Loadable({ // 骑士卡-app内置页
    loader: () => import('../containers/KnightCard'),
    loading: Loading
});
const LandingNewComer = Loadable({ // 推广着陆页
	loader: () => import('../containers/LandingNewComer'),
	loading: Loading
});
const LandingMiniProgram = Loadable({ // 小程序推广页
	loader: () => import('../containers/LandingMiniProgram'),
	loading: Loading
});
const LandingOut = Loadable({ // 推广着陆页
    loader: () => import('../containers/LandingOut'),
    loading: Loading
});
const BargainList = Loadable({ // 砍价免单列表
	loader: () => import('../containers/BargainList'),
	loading: Loading
});
const BargainDetail = Loadable({ // 砍价详情
	loader: () => import('../containers/BargainDetail'),
	loading: Loading
});
const ProductDetail = Loadable({ // 商品详情详情
	loader: () => import('../containers/ProductDetail'),
	loading: Loading
});

const LandingZero = Loadable({ // 推广0元着陆页
	loader: () => import('../containers/LandingZero'),
	loading: Loading
});

const LandingCashGift = Loadable({ // 现金红包推广页
	loader: () => import('../containers/LandingCashGift'),
	loading: Loading
});

const LandingQsk = Loadable({ // 现金红包推广页
	loader: () => import('../containers/LandingQsk'),
	loading: Loading
});

const LandingApp = Loadable({
	loader: () => import('../containers/LandingApp'),
	loading: Loading
});

const LandingCard = Loadable({
	loader: () => import('../containers/LandingCard'),
	loading: Loading
});

const LandingWatchAccount = Loadable({
	loader: () => import('../containers/LandingWatchAccount'),
	loading: Loading
});

const LandingMiniCash = Loadable({
	loader: () => import('../containers/LandingMiniCash'),
	loading: Loading
});

const LandingMiniPhone = Loadable({
	loader: () => import('../containers/LandingMiniPhone'),
	loading: Loading
});

const LandingMiniPhoneList = Loadable({
	loader: () => import('../containers/LandingMiniPhoneList'),
	loading: Loading
});

const LandingMini = Loadable({
	loader: () => import('../containers/LandingMini'),
	loading: Loading
});

const LandingAppCash = Loadable({
	loader: () => import('../containers/LandingAppCash'),
	loading: Loading
});

const LandingZeroTabs = Loadable({ // tabs
	loader: () => import('../containers/LandingZeroTabs'),
	loading: Loading
});
const LandingZeroCeiling = Loadable({ // 吸顶
	loader: () => import('../containers/LandingZeroCeiling'),
	loading: Loading
});
const LandingZeroDrop = Loadable({ // 下拉
	loader: () => import('../containers/LandingZeroDrop'),
	loading: Loading
});
const LandingZeroDropLpm = Loadable({ // lpm新返回拦截
	loader: () => import('../containers/LandingZeroDropLpm'),
	loading: Loading
});
const LandingZeroDropZZ = Loadable({ // 中转
	loader: () => import('../containers/LandingZeroDropZZ'),
	loading: Loading
});
const LandingZeroBottomBanner = Loadable({ // 底部banner
	loader: () => import('../containers/LandingZeroBottomBanner'),
	loading: Loading
});
const LandingZeroExcitationVideo = Loadable({ // 激励视频
	loader: () => import('../containers/LandingZeroExcitationVideo'),
	loading: Loading
});
const LandingZeroModelspec = Loadable({ // 推广0元着陆页
	loader: () => import('../containers/LandingZeroModelspec'),
	loading: Loading
});
const LandingZeroGiftBox = Loadable({  //添加砸蛋动画
	loader: () => import('../containers/LandingZeroGiftBox'),
	loading: Loading
});

const LandingZeroNewBanner = Loadable({ // lpm着陆页
	loader: () => import('../containers/LandingZeroNewBanner'),
	loading: Loading
});
const LandingZeroTurnTable = Loadable({ // lpm着陆页添加转盘
	loader: () => import('../containers/LandingZeroTurnTable'),
	loading: Loading
});

const LandingZeroSmall = Loadable({ // 小程序着陆页
	loader: () => import('../containers/LandingZeroSmall'),
	loading: Loading
});

const LandingZeroModal = Loadable({ // 推广0元着陆页
	loader: () => import('../containers/LandingZeroModal'),
	loading: Loading
});

const LandingZeroBack = Loadable({ // 推广0元着陆页
	loader: () => import('../containers/LandingZeroBack'),
	loading: Loading
});

const LandingZeroDisplay = Loadable({ // 推广0元着陆页
	loader: () => import('../containers/LandingZeroDisplay'),
	loading: Loading
});

const LandingZeroBanner = Loadable({ // 推广0元着陆页
	loader: () => import('../containers/LandingZeroBanner'),
	loading: Loading
});

const LandingZeroIcon = Loadable({ // 推广0元着陆页
	loader: () => import('../containers/LandingZeroIcon'),
	loading: Loading
});

const LandingZeroOpen = Loadable({
	loader: () => import('../containers/LandingZeroOpen'),
	loading: Loading
});

const LandingZeroFree = Loadable({
	loader: () => import('../containers/LandingZeroFree'),
	loading: Loading
});
const LandingZeroSmashEgg = Loadable({  //添加砸蛋动画
	loader: () => import('../containers/LandingZeroSmashEgg'),
	loading: Loading
});
const LandingZeroGoldEggDrop = Loadable({  //砸金蛋 下拉 跳转
	loader: () => import('../containers/LandingZeroGoldEggDrop'),
	loading: Loading
});
const LandingHB = Loadable({
    loader: () => import('../containers/LandingHB'),
    loading: Loading
})
const LandingTest = Loadable({
    loader: () => import('../containers/LandingTest'),
    loading: Loading
})
const LandingPC = Loadable({ // 推广 PC 着陆页
	loader: () => import('../containers/LandingPC'),
	loading: Loading
});
const InviteFriends = Loadable({
	loader: () => import('../containers/InviteFriends'),
	loading: Loading,
});
const share = Loadable({
    loader: () => import('../containers/share'),
    loading: Loading,
});
const HelpBargain = Loadable({ // 帮砍页面
	loader: () => import('../containers/HelpBargain'),
	loading: Loading,
});
const BargainLanding = Loadable({ // 帮砍着陆页
	loader: () => import('../containers/BargainLanding'),
	loading: Loading,
});
const Seven = Loadable({ // 七夕活动页
	loader: () => import('../containers/Seven'),
	loading: Loading,
});
const RedPacketSend = Loadable({ // 社群红包
	loader: () => import('../containers/RedPacketSend'),
	loading: Loading,
});
const Adsense = Loadable({ // 视频广告
	loader: () => import('../containers/Adsense'),
	loading: Loading,
});
const luckDraw = Loadable({ // 抽奖活动
	loader: () => import('../containers/active/luckDraw'),
	loading: Loading,
});
const doubleFestival = Loadable({ // 年货节
	loader: () => import('../containers/active/doubleFestival'),
	loading: Loading
});

const MidLarge = Loadable({ // 618抽奖活动
	loader: () => import('../containers/active/MidLarge'),
	loading: Loading
});

const pennyPurchase = Loadable({ // 会员一分购
	loader: () => import('../containers/active/pennyPurchase'),
	loading: Loading
});
const LandingMiniBuy = Loadable({ // 会员一分购-小程序外推页
	loader: () => import('../containers/active/pennyPurchase/LandingMiniOneBuy'),
	loading: Loading
});
const PennyPurchaseNavigation = Loadable({ // 会员一分购1分购外推页
	loader: () => import('../containers/active/PennyPurchaseNavigation'),
	loading: Loading
});
const PennyPrivilegeNavigation = Loadable({ // 会员专享一分购 外推页
	loader: () => import('../containers/active/PennyPrivilegeNavigation'),
	loading: Loading
});
const PennyAppNavigation = Loadable({ // 会员专享一分购 往App引导的外推页
	loader: () => import('../containers/active/PennyAppNavigation'),
	loading: Loading
});
const AppPromotion = Loadable({ // 会员专享一分购 往App引导的外推页===新写的
	loader: () => import('../containers/active/AppPromotion'),
	loading: Loading
});
const SuperVipProduction = Loadable({ // 超级VIP会员说明介绍（新改版）
	loader: () => import('../containers/active/SuperVipProduction'),
	loading: Loading
});
const AnnualLivingInterest = Loadable({ // 年度生活权益
	loader: () => import('../containers/active/AnnualLivingInterest'),
	loading: Loading
});
const CommonDownloadPage = Loadable({ // 通用下载中间页面
	loader: () => import('../containers/DownloadPage'),
	loading: Loading
});
const NewerActivity = Loadable({ // 新人礼活动说明
	loader: () => import('../containers/active/NewerActivity'),
	loading: Loading
});
const MakeMoneyTricks = Loadable({ // 赚钱攻略说明
	loader: () => import('../containers/active/MakeMoneyTricks'),
	loading: Loading
});
const CommonProblemSummarize = Loadable({ // 常见问题汇总
	loader: () => import('../containers/active/CommonProblemSummarize'),
	loading: Loading
});
const FansOrderProblem = Loadable({ // 粉丝订单说明
	loader: () => import('../containers/active/FansOrderProblem'),
	loading: Loading
});
const BBabyLive = Loadable({ // 直播大厅
	loader: () => import('../containers/active/BBabyLive'),
	loading: Loading
});
/****************** 以下是易积分商城路由 开始 ******************/

const ScoreList = Loadable({ // 积分+代币兑换的商品列表
	loader: () => import('../containers/score/List'),
	loading: Loading
});

const ScoreDetail = Loadable({ // 详情页
	loader: () => import('../containers/score/Detail'),
	loading: Loading
});

const ScoreCouponDetail = Loadable({ // 优惠券详情页
	loader: () => import('../containers/score/CouponDetail'),
	loading: Loading
});

const ScoreOrder = Loadable({ // 订单页
	loader: () => import('../containers/score/Order'),
	loading: Loading
});

const ScoreOrderList = Loadable({ // 订单列表
	loader: () => import('../containers/score/OrderList'),
	loading: Loading
});

const ScoreConfirmOrder = Loadable({ // 确认订单页
	loader: () => import('../containers/score/ConfirmOrder'),
	loading: Loading
});
const ScoreAddress = Loadable({ // 收货地址
	loader: () => import('../containers/score/Address'),
	loading: Loading
});
const ScoreAddressEdit = Loadable({ // 添加/编辑收货地址
	loader: () => import('../containers/score/AddressEdit'),
	loading: Loading
});
const ScoreOrderDetail = Loadable({ // 订单详情
	loader: () => import('../containers/score/OrderDetail'),
	loading: Loading
});

const ScorePaySuccess = Loadable({ // 支付成功
	loader: () => import('../containers/score/PaySuccess'),
	loading: Loading
});

const ScoreExchangeResults = Loadable({ // 优惠券兑换结果页
	loader: () => import('../containers/score/ExchangeResults'),
	loading: Loading
});

const ScoreLogistics = Loadable({ // 物流信息
	loader: () => import('../containers/score/Logistics'),
	loading: Loading
});

/****************** 以上是易积分商城路由 结束 ******************/

const routesConfig = [
    {path: '/', exact: true, component: LandingPC, title: '易购宝贝，上易购宝贝首单0元购'},
	{path: '/a', exact: true, component: RouteA, title: 'A'},
	{path: '/b', exact: false, component: RouteB, title: 'B'},
	{path: '/c', exact: false, component: RouteC, title: 'C'},
	{path: '/score_list', exact: false, component: ScoreList, title: '易购宝贝'},
	{path: '/score_detail', exact: false, component: ScoreDetail, title: '商品详情'},
	{path: '/score_coupon_detail', exact: false, component: ScoreCouponDetail, title: '优惠券详情'},
	{path: '/score_order', exact: false, component: ScoreOrder, title: 'ScoreOrder'},
	{path: '/score_order_list', exact: false, component: ScoreOrderList, title: '订单列表'},
	{path: '/score_confirm_order', exact: false, component: ScoreConfirmOrder, title: '确认订单'},
	{path: '/score_address', exact: false, component: ScoreAddress, title: '收货地址'},
	{path: '/score_address_edit', exact: false, component: ScoreAddressEdit, title: '添加/编辑收货地址'},
	{path: '/score_order_detail', exact: false, component: ScoreOrderDetail, title: '我的订单'},
	{path: '/score_pay_success', exact: false, component: ScorePaySuccess, title: '支付成功'},
	{path: '/score_exchange_results', exact: false, component: ScoreExchangeResults, title: '兑换结果'},
	{path: '/score_logistics', exact: false, component: ScoreLogistics, title: '物流信息'},
	{path: '/red_packet_test', exact: false, component: RedPacketTest, title: 'RedPacketTest'},
	{path: '/red_packet_spell', exact: false, component: RedPacketSpell, title: '拼手气红包'},
	{path: '/red_packet_earn', exact: false, component: RedPacketEarn, title: '赚红包'},
	{path: '/new_comer', exact: false, component: NewComer, title: '新人专享'},
	{path: '/order_return', exact: false, component: OrderReturn, title: '每日上新'},
	{path: '/order_return_rule', exact: false, component: OrderReturnRule, title: '下单返现'},
	{path: '/new_tactics', exact: false, component: Tactics, title: '新人攻略'},
	{path: '/help_center', exact: false, component: HelpCenter, title: '帮助中心'},
	{path: '/explain', exact: false, component: Explain, title: '红包说明'},
	{path: '/sharing_explain', exact: false, component: ExplainSharing, title: '用户协议'},
	{path: '/vip_explain', exact: false, component: VipExplain, title: '会员说明'},
	{path: '/vip_invite', exact: false, component: VipInvite, title: '邀请会员'},
	{path: '/out_vip_invite', exact: false, component: VipInviteOut, title: '分享赚钱'},
	{path: '/vip_center', exact: false, component: VipCenter, title: '会员中心'},
	{path: '/vip_record', exact: false, component: VipRecord, title: '邀请记录'},
	{path: '/out_vip_center', exact: false, component: OutVipCenter, title: '会员中心'},
	{path: '/vip_detail', exact: false, component: VipDetail, title: '填写信息'},
	{path: '/vip_order', exact: false, component: VipOrder, title: '确认订单'},
	{path: '/vip_success', exact: false, component: VipSuccess, title: '支付成功'},
	{path: '/vip_success_after', exact: false, component: VipSuccessAfter, title: '开通成功'},
	{path: '/user_agreement', exact: false, component: UserAgreement, title: '用户协议'},
	{path: '/privacy_agreement', exact: false, component: PrivacyAgreement, title: '隐私政策'},
	{path: '/landing', exact: false, component: Landing, title: '免费领1800元新人奖'},
    {path: '/share', exact: false, component: share, title: '分享'},
	{path: '/landing_new_comer', exact: false, component: LandingNewComer, title: '新人专享'},
    {path: '/landingOut', exact: false, component: LandingOut, title: '领红包'},
	{path: '/bargain_list', exact: false, component: BargainList, title: '邀好友砍价'},
	{path: '/bargain_detail', exact: false, component: BargainDetail, title: '砍价详情'},
	{path: '/product_detail', exact: false, component: ProductDetail, title: '商品详情'},
	{path: '/HelpBargain', exact: false, component: HelpBargain, title: '砍价免费拿'},
	{path: '/BargainLanding', exact: false, component: BargainLanding, title: '砍价免费拿'},
	{path: '/landing_app', exact: false, component: LandingApp, title: '易购宝贝'},
	{path: '/landingCard', exact: false, component: LandingCard, title: '易购宝贝'},
	{path: '/landingWatchAccount', exact: false, component: LandingWatchAccount, title: '易购宝贝'},
	{path: '/landingMiniCash', exact: false, component: LandingMiniCash, title: '易购宝贝'},
	{path: '/landingMiniPhone', exact: false, component: LandingMiniPhone, title: '易购宝贝'},
	{path: '/landingMiniPhoneList', exact: false, component: LandingMiniPhoneList, title: '易购宝贝'},
	{path: '/landing_mini', exact: false, component: LandingMini, title: '易购宝贝'},
	{path: '/landingAppCash', exact: false, component: LandingAppCash, title: '易购宝贝'},
	{path: '/landingZero', exact: false, component: LandingZero, title: '好货0元购'},
	{path: '/landingCashGift', exact: false, component: LandingCashGift, title: '现金礼'},
	{path: '/landingZeroTabs', exact: false, component: LandingZeroTabs, title: '好货0元购'},
	{path: '/landingZeroBottomBanner', exact: false, component: LandingZeroBottomBanner, title: '好货0元购'},
	{path: '/landingZeroCeiling', exact: false, component: LandingZeroCeiling, title: '好货0元购'},
	{path: '/landingZeroDrop', exact: false, component: LandingZeroDrop, title: '好货0元购'},
	{path: '/landingZeroDropLpm', exact: false, component: LandingZeroDropLpm, title: '好货0元购-新返回拦截'},
	{path: '/landingZeroDropZZ', exact: false, component: LandingZeroDropZZ, title: '好货0元购-中转'},
	{path: '/landingZeroExcitationVideo', exact: false, component: LandingZeroExcitationVideo, title: '好货0元购'},
	{path: '/landingZeroNewBanner', exact: false, component: LandingZeroNewBanner, title: '好货0元购' },
	{path: '/landingZeroModelspec', exact: false, component: LandingZeroModelspec, title: '好货0元购'},
	{path: '/landingZeroSmall', exact: false, component: LandingZeroSmall, title: '好货0元购'},
	{path: '/landing_mini_program', exact: false, component: LandingMiniProgram, title: '新人专享'},
	{path: '/landingZeroModal', exact: false, component: LandingZeroModal, title: '好货0元购'},
	{path: '/landingZeroBack', exact: false, component: LandingZeroBack, title: '好货0元购'},
	{path: '/landingZeroDisplay', exact: false, component: LandingZeroDisplay, title: '好货0元购'},
	{path: '/landingZeroBanner', exact: false, component: LandingZeroBanner, title: '好货0元购'},
	{path: '/landingZeroIcon', exact: false, component: LandingZeroIcon, title: '好货0元购'},
	{path: '/landingZeroOpen', exact: false, component: LandingZeroOpen, title: '好货0元购'},
	{path: '/landingZeroFree', exact: false, component: LandingZeroFree, title: '好货0元购'},
	{path: '/landingZeroSmashEgg', exact: false, component: LandingZeroSmashEgg, title: '好货0元购' },
	{path: '/landingZeroGiftBox', exact: false, component: LandingZeroGiftBox, title: '好货0元购' },
	{path: '/landingZeroTurnTable', exact: false, component: LandingZeroTurnTable, title: '好货0元购'},
	{path: '/landingZeroGoldEggDrop', exact: false, component: LandingZeroGoldEggDrop, title: '好货0元购'},
    {path: '/landingHB', exact: false, component: LandingHB, title: '您有一个红包未领取'},
	{path: '/landingPC', exact: false, component: LandingPC, title: '易购宝贝，上易购宝贝首单0元购'},
    {path: '/landingTest', exact: false, component: LandingTest, title: '易购宝贝，上易购宝贝首单0元购'},
	{path: '/invite_friends', exact: false, component: InviteFriends, title: '邀好友赚红包'},
	{path: '/seven', exact: false, component: Seven, title: '宝贝七夕节'},
	{path: '/red_packet_send', exact: false, component: RedPacketSend, title: '福利'},
	{path: '/adsense', exact: false, component: Adsense, title: '视频广告'},
	{path: '/luckDraw', exact: false, component: luckDraw, title: '抽奖活动'},
	{path: '/fiveball1', exact: false, component: fiveball1, title: '易购宝贝'},
	{path: '/landingMiniFree', exact: false, component: LandingMiniFree, title: '易购宝贝'},
	{path: '/fiveball2', exact: false, component: fiveball2, title: '易购宝贝'},
	{path: '/fiveball3', exact: false, component: fiveball3, title: '易购宝贝'},
	{path: '/fiveball4', exact: false, component: fiveball4, title: '易购宝贝'},
	{path: '/fiveball5', exact: false, component: fiveball5, title: '易购宝贝'},
	{path: '/fiveball8', exact: false, component: fiveball8, title: '易购宝贝'},
	{path: '/fiveball9', exact: false, component: fiveball9, title: '易购宝贝'},
	{path: '/tonglan23', exact: false, component: tonglan23, title: '易购宝贝'},
	{path: '/shuangdan', exact: false, component: shuangdan, title: '易购宝贝'},
	{path: '/welfare', exact: false, component: VipWelfare, title: '会员福利'},
	{path: '/double', exact: false, component: doubleFestival, title: '年货节'},
	{path: '/large', exact: false, component: MidLarge, title: '天天抽免单'},
	{path: '/pennyBuy', exact: false, component: pennyPurchase, title: '会员一分购'},
	{path: '/landingMiniBuy', exact: false, component: LandingMiniBuy, title: '会员一分购'},
	{path: '/throwin', exact: false, component: PennyPurchaseNavigation, title: '瓜分亿元补贴'},
	{path: '/pennyprivilege', exact: false, component: PennyPrivilegeNavigation, title: '会员专享一分购'},
	{path: '/appprivilege', exact: false, component: PennyAppNavigation, title: '会员专享一分购'},
	{path: '/apppromotion', exact: false, component: AppPromotion, title: '会员专享一分购'},
	{path: '/vipproduction', exact: false, component: SuperVipProduction, title: '会员专属特权'},
	{path: '/liverights', exact: false, component: AnnualLivingInterest, title: '年度生活权益'},
	{path: '/download', exact: false, component: CommonDownloadPage, title: '易购宝贝'},
	{path: '/newer', exact: false, component: NewerActivity, title: '活动说明'},
	{path: '/mm_tricks', exact: false, component: MakeMoneyTricks, title: '赚钱攻略'},
	{path: '/com_pro_sum', exact: false, component: CommonProblemSummarize, title: '常见问题汇总'},
	{path: '/fans_order_pro', exact: false, component: FansOrderProblem, title: '粉丝订单说明'},
	{path: '/live_hall', exact: false, component: BBabyLive, title: '直播大厅'},
	{path: '/landingknightcard', exact: false, component: LandingKnightCard, title: '骑士卡'},
	{path: '/landingQsk', exact: false, component: LandingQsk, title: '骑士卡'},
	{path: '/knightcard', exact: false, component: KnightCard, title: '骑士卡'},
];
 
const RouteWithSubRoutes = route => {
	return (
		<Route
			exact={route.exact}
			path={route.path}
			render={_ => {
				document.title = route.title || "";
				return <route.component {..._} routes={route.routes} />
			}}
		/>

	);
};

const mapRoutes = () => (
	routesConfig.map((item, index) => (
		<RouteWithSubRoutes key={index} {...item}/>
	))
);

export { mapRoutes };
